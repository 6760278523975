.logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
}

.app-logo {
    float: left;
    margin-right: 24px;
    margin-top: 12px;
}


.app-header {
    padding: 0px !important;
    width: 100% !important;
    z-index: 100 !important;
    right: 0px !important;
}

.overflow-hidden { overflow: hidden;}
.text-blue { color: #3399ff; }
.white-container { border-top: 4px solid #3399ff; margin: -5em 5em; background: #fff; z-index: 9; border-radius: 10px; padding: 1em; box-shadow: 0px 2px 4px rgba(148,163,184,.05),0px 6px 24px rgba(235,238,251,.4); }

/* .lg-justify-end { justify-content: end; } */
.position-sticky { position: sticky; }
.top5-em { top: 5em; }

.advanceFilter { background: #f8f9fa; border-radius: 15px; }
.advanceFilter .ant-collapse-item { background: #fff; border-radius: 10px!important; margin-bottom: 1em; border: 1px solid #E7E7F1; transition: 0.3s all; }
.advanceFilter .ant-collapse-item:hover { box-shadow: 0 6px 12px rgba(30,10,58,.04); transition: 0.3s all;}
.advanceFilter .ant-collapse-header-text { font-weight: bold;}
.advanceFilter .ant-collapse-borderless { background: none; }
.advanceFilter .ant-radio-inner, .advanceFilter .ant-checkbox-inner, .advanceFilter .ant-select-selector { border-color: #717b9e!important;}
.advanceFilter .ant-select-selection-placeholder, .advanceFilter .ant-select-arrow {color: #536777; }

a:hover { color: #3399ff!important;}
a:hover span { color: #3399ff!important;}

.padding-container { padding-left: 4em; padding-right: 4em; }

/*Home header*/
.mobileMenuWrapper { background: #F7F8FA!important; }
.mobileMenu .profileWrapper .ant-avatar { margin-bottom: 1em; }
.mobileMenu .profileWrapper .ant-space-item:nth-child(2n) {
    width: 100%;
}
.mobileMenu .profileWrapper .ant-space-item:nth-child(3n) {
    position: absolute;
    top: -0.45em;
    left: 50%;
    transform: translateX(-50%);
}
.mobileMenu .profileWrapper .ant-progress-inner {    
    width: 80px !important;
    height: 80px !important;
}
.mobileMenuWrapper .ant-drawer-header { border-bottom: none!important;}
.mobileMenuWrapper .ant-drawer-header svg { width: 1.3em; height: 1.3em; }
.mobileMenu .menuText { display: block; margin-bottom: 0.5em;}
.mobileMenu .profileWrapper { margin: 0 0 2em 0; height: auto!important; }
.mobileMenu .profileWrapper .ant-space { padding: 0; box-shadow: none; background: none; display: flex; align-items: center; flex-wrap: wrap; text-align: center; justify-content: center; }
.mobileMenu .profileWrapper .ant-space h4, .mobileMenu .profileWrapper .ant-space .userTitle { color: #000!important;}
.mobileMenu .ant-space-item { border: none!important;}
.mobileLogBtn { display: flex; justify-content: center; align-items: center; position: absolute; left: 5%; bottom: 1.5em; width: 90%; background: #E5E8EC; border: 1px solid #D9DDE3; color: #404040; }
.homeHeaderBg { padding-top: 8em; padding-bottom: 3em; position: relative; background: url('../images/bg/homeHeaderBg.jpg') no-repeat; background-size: cover; background-position: center;}
.homeHeaderBg:after { content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: #061041; opacity: 0.7;}
.homeHeaderBg > div:first-child { z-index: 55;}
.homeHeaderBg div {  z-index: 44;}


/*Home header*/

/*bg*/
.bg-gray-1 { background-color: #f8f9fa; }
.bg-Gray-light { background: rgb(247, 248, 250); padding: 1.2em; border-radius: 1em; }
.white-rounded-bg { background: rgb(255, 255, 255); padding: 2em; border: 1px solid rgb(240, 240, 240); border-radius: 10px; margin-bottom: 2em;}
/*bg*/

/*flex*/
.flex  { display: flex;}
.justify-end { justify-content: end;}
.justify-between { justify-content: space-between;}
.align-item-center { align-items: center;}
/*flex*/
.profileWrapper .ant-space {
    width: 92%;
    padding: 0 1em 0 1.5em;
    background: rgba(0, 0, 0, .47);
    box-shadow: 0px 5px 10px #000000C2;
    border-radius: 20px;
}

.ant-btn-primary { text-wrap: wrap; background-color: #3399ff!important; color: #fff!important; padding-left: 2em!important; padding-right: 2em!important;}

/*ant slider track */
.ant-slider-track { background-color: #3399ff!important;}
/*ant slider track */

.prevSearch { padding: 1em; border-bottom: 1px solid #e6e9ee; }
.prevSearch:hover { background: #f9f9fb; }
.prevSearchCard .ant-card-body { padding: 0!important;}
.heading-Sidebar { background: #f9f9fb; border-bottom: 1px solid #dcdce1; position: sticky; top: 0;}


.mMenuIcon {
    display: none;
}

.mobileMenu {
    display: none;
}

.leftMenu {
    float: left;
  }
  
.rightMenu {
    position: absolute;
    right: 4em;
}

.menuIcon{
    font-size: 20px;
    
    cursor: pointer;
}
.menuText{
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.menuIronText{
    color: #fff;
    font-size: 16px;
    padding-right: 16px;
    cursor: pointer;
}
.linkIronText {
    padding-right: 16px;
}


.static-Header .ant-layout-header {
    background: #262626 !important;
    line-height: 36Px !important;
    height: 37px !important;   
    padding: 0rem 8rem !important;
}

.static-header.active { position: fixed; background: #fff!important; box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),0 3px 6px 0 rgba(0, 0, 0, 0.12),0 5px 12px 4px rgba(0, 0, 0, 0.09); transition: 0.3s all; }
.static-header.active .ant-space-item a span { color: #000!important; transition: 0.3s all;}

.ant-layout-header {
    padding-left: 2em!important;
    padding-right: 2em!important;
    background: transparent !important;    
    position: absolute;
}

.site-layout-background {
    background: #fff;
}

.right {
    display: flex;
    float: right;
    height: 100%;
    margin-left: auto;
    overflow: hidden;
}

.authHeaderIcon a {
    color: #fff;
}


.registerBody {
    padding: 1em;
}

/* .login-form {
    background-color: #eee;
    padding-top: .5rem;
    padding-bottom: 1rem;
} */
.pointer {
    cursor: pointer;
}

.heading-1 { font-size: 1.5em!important;  }

.ActiveCard {
    border: 1px solid #3399ff;
    background-color: #f9f9fb;
    cursor: pointer;
}

#form_Search .login-form-button { width: 100%; padding: 0!important;}
.fpbAob { background: #3399ff!important; }
.login-form-button:hover { background: none!important; border: 1px solid #3399ff; color: #3399ff!important;}
.jobCard:hover .ApplyBtn { opacity: 1;}
.ApplyBtn { transform: translateY(-34px); padding-left: 2.5em!important; padding-right: 2.5em!important; height: 3em!important; font-weight: bold; border-radius: 2em!important; opacity: 0; font-size: 1.2em; }

.ant-btn { min-height: 3em!important; height: auto; font-weight: bold; border-radius: 2em!important; font-size: 1em; }
.ant-btn.outline { padding-left: 2em!important; padding-right: 2em!important;}

.ant-card { box-shadow: 0 2px 20px rgba(30,10,58,.04);  }
.hoverCard:hover { border: 1px solid #3399ff;}
.w-full { width: 100%;}

.jobCard {
 margin: 15px 15px;
 
}

.Card-Grid {
    width: 100% !important;
    cursor: pointer;    
}

.applicationStage {
    border-bottom: 4px solid #0AA500;
}
.FloatRight {
    float: right !important;
}

.TextCenter {
    text-align: center;
}

.text-left { text-align: left; }

.TextRight {
    text-align: right;
}

.RegisterImg {
    height: 99.5vh !important;
}

.B-Right {
    border-right: 1px solid #eee;
}

.B-Left {
    border-left: 1px solid #eee;
}
.B-Top {
    border-top: 2px solid #eee;
}

.B-Top-1 {
    border-top: 2px solid #eee;
}

.B-Bottom {
    border-bottom: 1px solid #eee;
}

.ant-rate {
    font-size: 14px !important;
}

.AppColor {
    color: #3399ff;
}

.Singup .ant-form-item {
    width: 31rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.2rem;    
}

.searchLeftBg .ant-input-affix-wrapper >input.ant-input {
    height: 2.9em!important;
}

.ant-input-affix-wrapper >input.ant-input::placeholder, .ant-select-selection-placeholder {
    color: #121224!important; font-weight: 400;
}

.ant-select-selection-search-input
{
    height: 3.1em !important;
}

.Singup .bluelogo {
    height: 65px;
    margin: 20px 0px 0px 0;
}
.mt-44 {
    margin-top: 44px !important;
}

.footer-p {
    padding: 0 25px !important;
}

.Singup .otpBox {
    width: 15rem;
    margin: 0 auto !important;
}

.loginBody .bluelogo {
    height: 50px;
    margin: 20px 0px 0px 0;
}

.SearchForm {
    text-align: center;
}

.SearchForm form {
    margin: 0 auto;
    width: 60%;
}

.SearchForm .title {
    padding: 1rem 10rem;
}

.SearchForm .ant-form-item {
    margin-bottom: 12px;
}

.InheritSearch .ant-form-inline {
    width: 100% !important;
 }





.site-collapse-custom-collapse .ant-radio-wrapper {
    width: 100%;
    padding-bottom: 5px;
}

.site-collapse-custom-collapse .ant-checkbox-group {
    display: block;
}

.site-collapse-custom-collapse .ant-checkbox-wrapper {
    width: 100%;
    padding-bottom: 5px;
    display: flex;
    margin-inline-start: 0px;
}

.site-collapse-custom-collapse .ant-form-item {
    margin-bottom: 0px;
}


.JobListVSroll {
    /* height: calc(100vh - 19em);
    overflow-y: auto; */
    overflow-x: hidden;
    padding-bottom: 1em;
}

.ant-progress-text {
    font-weight: bold;
}

.error {
    color: red;
}
.paddingTop {
    padding-top:26px;
}

.lr-card {
    max-height: 500px;
}
.savedJobsContainer .lr-card { max-height: none; }
.savedJobsContainer .heading-Sidebar { background: none!important;}
.lr-card-date {
    color: #8E9BA9;
    font-size: 14px;
    font-weight: 500;
    display: block;
}
.recSearchJob { font-size: 1.1em; font-weight: bold;}

.scrollbox {
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollbox::-webkit-scrollbar {
    background-color: transparent;
    width: 0px;
}

.scrollbox:hover::-webkit-scrollbar {
    width: 5px;
}

.scrollbox:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .2)
}
.modelScrollbox {
    overflow-y: scroll;
    height: 70vh;
}

.text-white {
    color: #fff !important;
}

.text-capitalize {
    text-transform: capitalize;
}

.width-100 {
    width: 100%;
}


.login-container {
    height: calc(100vh - 0px);
    position: relative;
    background-color: #eee;
  }
  
  .vertical-center {
    margin: auto;
    position: absolute;
    top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  }

  .stytch-auth {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
  }

  .stytch-auth .login-form {
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 8px;
  }
  .stytch-auth .auth-form {
    padding: 24px 32px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 8px;
  }

  .eFYBTN {
display: none !important;
  }

  .PhoneInputCountry{
    border: 2px solid #eee !important;
    border-radius: 5px !important;
    padding: 0 10px !important;
}

.PhoneInput input:focus, .PhoneInput input:hover, .PhoneInput input:focus-visible {
    border-color: #4096ff !important;
}

.PhoneInput input:focus-visible {
    border-color: #4096ff !important;
    border: 1px solid #4096ff !important;
}

::placeholder {
    color: #d9d9d9;
    font-weight: 100;
    opacity: 1; /* Firefox */
  }
  
  ::-ms-input-placeholder { /* Edge 12 -18 */
    color: #d9d9d9;
    font-weight: 100;
  }

  :root {
    --PhoneInput-color--focus: #3399ff !important;
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 0.35em;
    --PhoneInputCountrySelectArrow-width: 0.3em;
    --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
    --PhoneInputCountrySelectArrow-borderWidth: 1px;
    --PhoneInputCountrySelectArrow-opacity: 0.45;  
    --PhoneInputCountryFlag-borderColor--focus: #3399ff !important;
}


.searchLeftBg { height: 10em; padding: 1em 1em 0 1.5em; background: rgba(0, 0, 0, .47); box-shadow: 0px 5px 10px #000000C2; border-radius: 20px; }
.searchLeftBg .ant-slider .ant-slider-rail { background: #fff; height: 15px; border-radius: 20px; }
.searchLeftBg .ant-slider .ant-slider-track { height: 15px; }
.searchLeftBg .ant-slider .ant-slider-handle { top: 50%; }


.profileWrapper .ant-space { position: relative ;}
.profileWrapper .ant-space-item:nth-child(3n) {position: absolute;
    top: 1.8em;
    left: 0.6em; }
.profileWrapper .ant-progress-inner { overflow: visible!important; width: 90px!important; height: 90px!important; }
.profileWrapper .ant-progress-text { color: white!important; font-size: 1.2em!important; position: relative; z-index: 9999999; top: 6.5em!important; }
.advSearchLink {width: 9rem;
    text-align: right;
    position: absolute;
    right: 2em;
    top: -3em;}

.profileWrapper .ant-progress-text { top: 6.2em !important; position: relative; font-size: 0.9em !important; color: #52c41a!important; }


.closeFilter { display: none; font-size: 1.2em; margin: 0.6em 1em 0 0; position: absolute; top: 1em; right: 1em; z-index: 555;}
.filterBtn { display: none; }
.barsBtn { background: none; border: none; }
.barsBtn .anticon { font-size: 1.5em; color: #fff; }
.active .barsBtn .anticon { color: #000; }

.filterOverlay { background: rgba(0, 0, 0, 0.5); position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999; opacity: 0; transition: 0.3s all; }
.filterOverlay.active { opacity: 1; transition: 0.3s all; }

.DPWrapper img {
    border-radius: 100%;
}

.DPWrapper .ant-progress {
    position: absolute;
    top: -9px;
    left: -9px;
}

.DPWrapper .ant-progress-inner {
    width: 129px!important;
    height: 100px !important;
    font-size: 18px;
    overflow: visible;
}

.ant-progress-circle-trail { stroke-width: 3; stroke: rgb(100, 116, 139, 0.3)!important;}
.ant-progress-circle-path { stroke-width: 3; stroke: #47b749!important;}
.DPWrapper .ant-progress-text{transform: translate(-50%, 5em)!important; color: #47b749!important; }
.prifileEditAdd { border: 0; font-size: 1.1em!important; background: transparent; width: 45px!important; height: 45px !important; margin-bottom: 0.4em; }
.prifileEditAdd:hover { background: #ebebeb; color: #000!important; }
.userBasicInfo { margin: 0 0 0 1.5em; display: inline-block;}
.profilePageWrapper .skillSlider  { padding-left: 0!important; }
.profilePageWrapper .skillSlider .ant-slider { width: 90%!important; }
.profilePageWrapper .ant-slider-rail { height: 9px; margin-top: -3px;}
.profilePageWrapper .skillSlider .ant-slider .ant-slider-track  { background-color: #3399ff!important; height: 9px; margin-top: -3px;}

.editSteps { position: sticky; top: 5.5em; margin-top: 0; background: #fff;  z-index: 9; border-radius: 10px;}
.PersonalInfoForm { background: #f7f8fa; padding: 2em; border-radius: 10px; }
.whiteBgForm { padding: 1.5em; border: 1px solid rgb(240, 240, 240); background: #fff; background: #fff;  border-radius: 10px;}
.editProfileForm label { font-weight: 500;}
.editProfileForm .ant-input-affix-wrapper {border: 1px solid #c6cbdc!important; height: 3em!important; }
.editProfileForm .ant-input-affix-wrapper >input.ant-input { color: #717b9e!important; }
.ant-select-selector { padding-top: 0.4em!important;  border: 1px solid #c6cbdc!important; min-height: 3em!important; color: #717b9e!important;}
.ant-picker, .ant-input-number-input { min-height: 3em!important; }
textarea { padding-top: 0.4em!important;  border: 1px solid #c6cbdc!important; color: #717b9e!important;}

/* #e7e7f1 */

.whiteBgBox { position: relative;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    width: 100%;
    background: #fff;
    box-shadow: 0 2px 20px rgba(30,10,58,.04);
    border-radius: 10px;
    padding: 1em; margin-bottom: 1.2em;}

.applied { padding: 0.6em 1.2em; font-size: 1em; font-weight: 500;}
.ant-drawer-title { font-size: 1.3em!important; }

.skillWrapper { max-height: 42em; overflow-y: auto;}

.ant-popconfirm-buttons button:first-child { width: 26%;}

.userTitle { display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; }
.ant-dropdown-menu-item-danger:hover { background: #3399ff!important;}
.ant-dropdown-menu-item-danger:hover a { color: #fff!important; }
.applyFilterBtn span {
    width: 100%;
    color: #fff;
    font-size: 1em;
    font-weight: bold;
    background: #3399ff;
    padding: 1em;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99999;
}

.notificationVScroll{
    max-height: 80vh;
    overflow-y: auto;
}