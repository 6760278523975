@media screen and (max-width: 1500px) {      
    .w-fullX1200 { max-width: 100%; flex: 0 0 100%;}
    .selfQuestion { margin-top: 1em;}
}

@media screen and (max-width: 1300px) {  
    .full-X1300 { flex: 0 0 100%; max-width: 100%;}
    .hide-X1300 { display: none; }
    .w-65X1300 { flex: 0 0 65%; max-width: 65%;}
    .w-35X1300 { flex: 0 0 35%; max-width: 35%;}
    .d-noneX1300 { display: none;} 
    .editProfileForm { max-width: 100%; flex: 0 0 100%; }   
    .ant-steps-item-content { display: block!important; }
    .ant-steps-item-title  {line-height: 21px!important; margin: 0.3em 0 0 0!important;}
}

@media screen and (max-width: 1200px) { 
    .displayBlockX1200 { display: block;}         
    .appStage { text-align: left!important;}
    .searchLeft { max-width: 75%; flex: 0 0 75%; }
    .profileRight { max-width: 25%; flex: 0 0 25%; }

    .JobListVSroll .jobCardMain { flex: 0 0 100%; max-width: 100%; }
    .leftSidebar { flex: 0 0 33%; max-width: 33%;}
    .rightSideJobBar { flex: 0 0 67%; max-width: 67%;}    
}

@media screen and (max-width: 1000px) {  
    .searchLeft { max-width: 100%; flex: 0 0 100%; margin-top: 2em; order: 2; }
    .profileRight { max-width: 100%; flex: 0 0 100%; }
    .profileWrapper .ant-space { width: 100%; }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 991px) { 
    .jobCard .ApplyBtn { opacity: 1!important;}
    .ant-drawer-content-wrapper { width: 65%!important;}
    .personalInfoWrapper.pa-4 { padding: 16px 0!important;}
    .add-more-pt { padding-top: 0 !important; }
    .editSteps { position: static !important; } 
    .editSteps h4 { margin-bottom: 1.2em; }
    .ant-steps-item-container { text-align: center;}
    .ant-steps-item-title { padding: 0.5em 0 0 0!important; margin: 0!important;}
    .w-65X1300, .w-35X1300 { flex:0 0 100%; max-width: 100%; }
    .filterBtnWrapper { margin-top: 1.2em; }
    .filterLeftWrapper { position: fixed;
        top: 0;
        left: -100%;
        z-index: 999;
        max-width: 38%;
        min-width: 23em; transition: 0.3s all;}
        .filterLeftWrapper.active { left: 0; top: -0.55em; transition: 0.3s all; height: 94vh; }
        /* .filterLeftWrapper.active {
            left: 0;
            top: 35em;
            bottom: 0;
            transition: 0.3s all;
            height: 100%;
            position: absolute!important;
        } */
    
        .filterLeftWrapper.active .position-sticky { position: static!important; height: 95vh; }
        .advanceFilter {border-radius: 0 15px 0 0; }
        .filterLeftWrapper .JobListVSroll { overflow-y: auto;
            height: calc(100vh - 13.5em);
            top: 84px;
            position: absolute;
            width: 91%;}
        .closeFilter { display: block; }
    .filterBtn { display: block;}
    .fliterProductWrapper { max-width: 100%; flex: 0 0 100%; }
    .rightSideJobBar { flex: 0 0 100%; max-width: 100%;}    
    .fliterProductWrapper h3, .fliterProductWrapper .JobListVSroll  { padding-left: 0!important; padding-right: 0!important;}
    .fliterProductWrapper .jobCard { margin-left: 0; margin-right:0;}

    .registerBody {
        padding: 2em 2em 2em 2em;
    }

    .Singup .bluelogo {        
        margin: 10px 0px 0px 0;
    }

    .Singup .ant-form-item {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }

    .Singup .otpBox {
        width: 8rem;
        margin: 0 auto !important;
    } 
    .stytch-auth .login-form {
        width: 97%;
    }
    .stytch-auth .auth-form {
        width: 97%;
    }
}


@media screen and (min-width: 767px) {   

    header .menuText, .bellIcon { color: #fff;}
}

@media screen and (max-width: 500px) {
    .white-container .w-65X1300 { padding-left: 0!important; padding-right: 0!important;}
    .w-65X1300.pl-6, .w-65X1300.pr-6 { padding-left: 10px!important; padding-right: 10px!important;}
    .PersonalInfoForm {padding: 0.2em;}
    .appliedStatus { position: static!important; margin-bottom: 0.5em;}    
    .jobCard .ApplyBtn { line-height: 21px; padding: 0!important; transform: translatey(-3em) translateX(0px); width: 42px; z-index: 99; background: none !important; box-shadow: none; }
    .jobCard .ApplyBtn span { display: none; }
    .jobCard .ApplyBtn:after { content: "\2192"; font-size: 2em; color: rgb(100, 116, 139); }
    .jobCard .ApplyBtn:after { content: ''; display: inline-block;
        position: absolute;
        width: 16px;
        height: 16px;
        background: transparent;
        text-indent: -9999px;
        border-top: 2px solid rgb(100, 116, 139);
        border-left: 2px solid rgb(100, 116, 139);
        transition: all 250ms ease-in-out;
        text-decoration: none;
        color: transparent;
        transform: rotate(135deg); }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 767px) {   
    .skillWrapper > .ant-row { padding: 1em!important; }
    .profileSkillSlider.pl-12 { padding-left: 30px!important; }
    .ant-drawer-content-wrapper { width: 75%!important;} 
    .noMobileLabel label { display: none!important;}    
    .profileSpace {flex-wrap: wrap; text-align: center; width: 100%; }
    .profileSpace .ant-space-item { width: 100%; }
    .profileSpace .ant-avatar { width: 80px!important; height: 80px !important; }
    .DPWrapper .ant-progress-inner { width: 105px !important; height: 105px !important; }
    .DPWrapper .ant-progress { position: relative; top: -6.59em; left: 0; }
    .DPWrapper .ant-progress-text { transform: translate(-50%, 4em)!important; }
    .userBasicInfo { margin-top: -3em; margin-left: 0; }
    .editProfileWrapper { position: absolute; top: 1em; right: 1em; }
    .profileWhiteWrapper { position: relative; }
    .profilePageWrapper { padding: 0; }

    .selfQuestion h5 { text-align: center; }
    .searchLeftBg { height: auto; }

    .static-Header .ant-layout-header {
        background: #262626 !important;
        line-height: 36Px !important;
        height: 37px !important;   
        padding: 0rem 1rem !important;
    } 
  
    .desktopMenu {
        display: none!important;
    }

    .mobileMenu {
        display: block;
    }

    .mMenuIcon {
        display: block;
    }

    .menuIcon{
        font-size: 20px;
        color: #fff; margin-right: 1em; margin-top: 2px;
        cursor: pointer;
    }
    .menuText{
        color: rgba(0, 0, 0, 0.88);
        font-size: 16px;
        cursor: pointer;
        padding-left: 2px;
    }

    .menuIronText{
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        padding-left: 2px;
    }

    .mobileMenu .ant-space {
        width: 100%;
    }

    .SearchForm form{
        display: block;
        width: 100%;
    }

    .SearchForm .title {
        padding: 1rem 0rem;
    }

    .SearchForm  .ant-form-item {
        padding-bottom: .5rem !important;
        margin-inline-end: 0px !important;
     }

    .InheritSearch form {
        display: block;
        width: 100%;
    }

    /* .InheritSearch  .ant-form-inline {
        width: 80% !important;
     } */

    .InheritSearch  .ant-form-item {
        padding-bottom: 1rem !important;
        margin-inline-end: 0px !important;
     }
    
    .registerBody {
        padding: 2em 2em 2em 2em;
    }

    .Singup .bluelogo {        
        margin: 10px 0px 0px 0;
    }

    .Singup .ant-form-item {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        width: auto;
    }

    .Singup .otpBox {
        width: 8rem;
        margin: 0 auto !important;
    }
}

@media screen and (max-width: 600px) { 
    .ant-drawer-content-wrapper { width: 100%!important;} 
    .rightMenu { right: 2em;}
    .ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-icon { margin-inline-end: 1em!important;}
    .ant-steps-item-content { text-align: left;}
    .white-container { margin-left: 1.3em; margin-right: 1.3em;}
    .padding-container, .ant-layout-header { padding-left: 1.3em!important; padding-right: 1.3em !important;}
    .app-logo { margin-left: -2em;}
}