.apply-form {
    padding-top: 4px;
}
.apply-form .ant-form-item-label {
    text-align: start;
    width: 90%;
}

:where(.css-dev-only-do-not-override-1s3dcof).ant-btn >span {
    display: inline-block;
    white-space: normal;
    word-wrap: break-word;
}